<template>
    <span v-if="loggedin && form">
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px" v-if="responses && responses.length > 0">
            <div class="d-flex flex-row align-start justify-center">
                <span class="px-3">
                    <h1 v-text="form.title"></h1>
                </span>
            </div>
            <v-tabs v-model="tab" color="primary" centered>
                <v-tab href="#tabSatisfaction">
                    <v-icon>mdi-emoticon-happy-outline</v-icon>
                    <span class="pl-3">Satisfaction</span>
                </v-tab>
                <v-tab href="#tabQuestions">
                    <v-icon>mdi-chat-question-outline</v-icon>
                    <span class="pl-3">Questions</span>
                </v-tab>
                <v-tab href="#tabIndividuel">
                    <v-icon>mdi-comment-account-outline</v-icon>
                    <span class="pl-3">Individuel</span>
                </v-tab>
                <v-tab href="#tabStats">
                    <v-icon>mdi-poll</v-icon>
                    <span class="pl-3">Stats</span>
                </v-tab>
                <v-tab href="#tabExport">
                    <v-icon>mdi-download</v-icon>
                    <span class="pl-3">Exporter</span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tabSatisfaction" >
                    <SatisfactionTab :responses="responses" :form="form"/>
                </v-tab-item>
                <v-tab-item value="tabQuestions" >
                    <QuestionsTab :responses="responses" :form="form"/>
                </v-tab-item>
                <v-tab-item value="tabIndividuel" >
                    <IndividualTab :responses="responses" :form="form"/>
                </v-tab-item>
                <v-tab-item value="tabStats" >
                    <StatsTab :responses="responses" :form="form"/>
                </v-tab-item>
                <v-tab-item value="tabExport" >
                    <ExportTab :responses="responses" :form="form"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px" v-else>
            <div class="d-flex flex-row align-start justify-center">
                <span class="px-3">
                    <h1 class="text-center" v-text="form.title"></h1>
                    <h2 class="text-center">Ce formulaire ne comporte actuellement aucune réponse</h2>
                </span>
            </div>
        </v-card>
        <v-dialog v-model="shareDialog" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="shareDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex flex-column align-center justify-center">
                        <span class="px-3 py-2" v-if="form.code.trim() != ''" >
                            <img :src="getQRUrl" style="max-width: 200px;max-height: 200px;"/>
                        </span>
                        <h2 class="py-2 text-center" v-text="form.title"></h2>
                        <h3 class="py-2 text-center" v-if="form.code.trim() != ''" v-text="'Code publique : ' + form.code"></h3>
                        <h3 class="py-2 text-center" v-else>Ce formulaire est privé</h3>
                        <span class="text-center mt-5" v-if="form.code.trim() != ''">
                            <v-btn color="primary" class="white--text" @click="copyFormLink">Copier le lien</v-btn>
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
    <span v-else-if="loggedin">
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px">
            <v-card-title primary-title class="justify-center">
                <h2>Ce formulaire n'est pas disponible pour le moment !</h2>
            </v-card-title>
            <v-card-text class="my-12 d-flex align-center justify-center">
                <v-btn color="primary" outlined @click="$router.push('/home')">Routourner a l'accueil</v-btn>
            </v-card-text>
        </v-card>
    </span>
    <span v-else>
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px">
            <v-card-title primary-title class="justify-center">
                <h2>Vous n'avez pas accès à cette page !</h2>
            </v-card-title>
            <v-card-text class="my-12 d-flex align-center justify-center">
                <v-btn color="primary" outlined @click="$router.push('/home')">Routourner a l'accueil</v-btn>
            </v-card-text>
        </v-card>
    </span>
</template>

<script>
import Form from '@/classes/Form.js'
import Response from '@/classes/Response.js'

import SatisfactionTab from '@/components/responses/SatisfactionTab.vue'
import StatsTab from '@/components/responses/StatsTab.vue'
import QuestionsTab from '@/components/responses/QuestionsTab.vue'
import IndividualTab from '@/components/responses/IndividualTab.vue'
import ExportTab from '@/components/responses/ExportTab.vue'

export default {
    name: "Response",
    props : ['loggedin'],
    components: {
        SatisfactionTab,
        StatsTab,
        QuestionsTab,
        IndividualTab,
        ExportTab,
    },
    data() {
        return {
            unsub : [],
            tab : 0,
            form : null,
            responses : [],
            shareDialog : false,
        }
    },
    computed: {
        getQRUrl(){
            return 'https://api.qrserver.com/v1/create-qr-code/?data='+ window.location.origin + '/form/' + this.form.id
        },
    },
    watch : {
        '$route' : {
            handler : function(){
                this.unsub.push(Form.listenById(this.$route.params.id, (form) => {
                    this.form = form
                    this.$forceUpdate()
                }))
                this.unsub.push(Response.listenByForm(this.$route.params.id, (responses) => {
                    this.responses = responses
                    this.$forceUpdate()
                }))
            },
            immediate : true
        }
    },
    methods : {
        triggerShare(){
            this.shareDialog = true
        },
        triggerView(){
            this.$router.push('/form/'+this.form.id)
        },
        copyFormLink(){
            let url = window.location.origin + '/form/' + this.form.id
            navigator.clipboard.writeText(url)
        }
    }
}
</script>
